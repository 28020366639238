<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail Dokumen Terkirim</b>
        <span class="pull-right">
          <button
            @click="onDownload"
            class="btn btn-sm btn-success"
            title="Print"
          >
            <i class="mdi mdi-printer"></i>Print Lembar Disposisi</button
          >&nbsp;
          <button
            @click="addReceiver"
            class="btn btn-sm btn-warning"
            title="Tambah Penerima"
          >
            <i class="mdi mdi-plus"></i>Tambah Penerima</button
          >&nbsp;
          <button
            @click="showModal({ type: 'bataldisposisi' })"
            class="btn btn-sm btn-danger"
            title="Batalkan Disposisi"
          >
            <i class="mdi mdi-close"></i>Batalkan Disposisi
          </button>
        </span>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs :tabIndex="1">
            <gtTab title="Detail Disposisi">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Nomor Surat</small>
                <h6>{{ state.detail.nomorsurat }}</h6>
                <small class="text-muted">Penerima</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.dispotujuan"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Personal</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.dispopersonal"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Instruksi</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.instruction"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Catatan</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.catatan"
                      v-bind:key="key"
                    >
                      <b>{{ item.unit_name }}</b
                      >&nbsp;
                      <p>{{ item.catatan }}</p>
                    </li>
                  </ul>
                </h6>
              </div>
            </gtTab>
            <gtTab title="Detail Surat">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Tanggal Surat</small>
                <h6>
                  {{
                    state.detail.tanggal_surat === ""
                      ? "-"
                      : formatDate(state.detail.tanggal_surat)
                  }}
                </h6>
                <small class="text-muted">Dari</small>
                <h6>
                  {{
                    state.detail.asalsurat === "" ? "-" : state.detail.asalsurat
                  }}
                </h6>
                <small class="text-muted">Jenis Dokumen / Nama Dokumen</small>
                <h6></h6>
                <small class="text-muted">Sifat Surat</small>
                <h6>
                  {{ state.detail.sifat === "" ? "-" : state.detail.sifat }}
                </h6>
                <small class="text-muted">Kecepatan Surat</small>
                <h6></h6>
              </div>
              <div class="col-md-6 pull-right">
                <small class="text-muted">Ringkasan Surat</small>
                <h6>{{ state.detail.description }}</h6>
                <small class="text-muted">Nomor Surat</small>
                <h6>
                  {{
                    state.detail.nomor_surat === ""
                      ? "-"
                      : state.detail.nomor_surat
                  }}
                </h6>
                <small class="text-muted">Halaman / Lampiran</small>
                <h6>
                  {{ state.detail.pages === "" ? "-" : state.detail.pages
                  }}{{ " / " }}
                  {{ state.detail.jumlah === "" ? "-" : state.detail.jumlah }}
                  {{
                    state.detail.document_lampiran === ""
                      ? "-"
                      : state.detail.document_lampiran.text
                  }}
                </h6>
                <small class="text-muted">Kepada</small>
                <h6 v-if="state.detail.receiver === ''">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.receiver"
                      :key="rec.value"
                    >
                      <td>{{ index + 1 + ". " + rec.text }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </h6>
                <small class="text-muted">Tembusan</small>
                <h6 v-if="state.detail.receiver2 === ''">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.receiver2"
                      :key="rec.value"
                    >
                      <td>{{ index + 1 + ". " + rec.text }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>

                  <!-- <table>

                      <tr v-for="(rec,index) in state.detail.carbon" :key="rec.value">
                        <td>{{ (index+1) + ". " + rec.text }}</td>
                        <td style="margin-left:10px;">
                          <i v-if="rec.approval==2" class="fa fa-envelope" style="cursor:pointer;background:blue;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==1" class="fa fa-envelope" style="cursor:pointer;background:green;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==3" class="fa fa-envelope" style="cursor:pointer;background:red;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                        </td>
                      </tr>

                    </table> -->
                </h6>
              </div>
              <hr />

              <!-- <div class="col-md-6 pull-left">
                <small class="text-muted">Catatan Konfirmasi / Retur </small>
                <h6>
                  <span
                    v-html="
                      state.detail.catatan_konfirmasi == ''
                        ? '-'
                        : state.detail.catatan_konfirmasi
                    "
                  ></span>
                </h6>
              </div> -->
            </gtTab>
            <!-- <gtTab title="Detail Surat">
              <div class="row">
                <div class="col-md-6 pull-left">
                  <small class="text-muted">Tanggal Surat</small>
                  <h6>
                    {{
                      state.detail.tanggal_surat === ""
                        ? "-"
                        : formatDate(state.detail.tanggal_surat)
                    }}
                  </h6>
                  <small class="text-muted">Pengirim</small>
                  <h6>
                    {{
                      state.detail.senderUnit === ""
                        ? "-"
                        : state.detail.senderUnit
                    }}
                  </h6>
                  <small class="text-muted">Asal Surat</small>
                  <h6>
                    {{
                      state.detail.asalsurat === ""
                        ? "-"
                        : state.detail.asalsurat
                    }}
                  </h6>
                  <small class="text-muted">Perihal</small>
                  <h6>
                    {{
                      state.detail.subject === "" ? "-" : state.detail.subject
                    }}
                  </h6>
                  <small class="text-muted">Agenda</small>
                  <h6>
                    {{
                      state.detail.agenda_number === ""
                        ? "-"
                        : state.detail.agenda_number
                    }}
                  </h6>
                  <small class="text-muted"
                    >Jenis Naskah Dinas / Nama Naskah Dinas
                  </small>
                  <h6>
                    {{
                      state.detail.document_jenis == "" ||
                      state.detail.document_jenis == null ||
                      state.detail.document_jenis == undefined
                        ? "-"
                        : state.detail.document_jenis.text
                    }}
                  </h6>
                  <small class="text-muted">Sifat Surat</small>
                  <h6>
                    {{ state.detail.sifat === "" ? "-" : state.detail.sifat }}
                  </h6>
                  <small class="text-muted">Kecepatan Surat</small>
                  <h6>
                    {{
                      state.detail.document_kecrat == "" ||
                      state.detail.document_kecrat == null ||
                      state.detail.document_kecrat == undefined
                        ? "-"
                        : state.detail.document_kecrat.text
                    }}
                  </h6>
                </div>

                <div class="col-md-6 pull-left">
                  <small class="text-muted">Ringkasan Surat</small>
                  <h6><span v-html="state.detail.description"></span></h6>
                  <small class="text-muted">Nomor Dokumen</small>
                  <h6>
                    {{
                      state.detail.nomor_surat === ""
                        ? "-"
                        : state.detail.nomor_surat
                    }}
                  </h6>
                  <small class="text-muted">Halaman / Lampiran</small>
                  <h6>
                    {{ state.detail.pages === "" ? "-" : state.detail.pages
                    }}{{ " / " }}
                    {{ state.detail.jumlah === "" ? "-" : state.detail.jumlah }}
                    {{
                      state.detail.document_lampiran === ""
                        ? "-"
                        : state.detail.document_lampiran.text
                    }}
                  </h6>
                  <small class="text-muted">Kepada</small>
                  <h6 v-if="state.detail.receiver === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                  <small class="text-muted">Tembusan</small>
                  <h6 v-if="state.detail.receiver2 === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver2"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                </div>
              </div>
            </gtTab> -->

            <gtTab title="File Terlampir">
              <div class="row">
                <div class="col-md-6 pull-left">
                  <small class="text-muted">Tanggal Surat</small>
                  <h6>
                    {{
                      state.detail.tanggal_surat === ""
                        ? "-"
                        : formatDate(state.detail.tanggal_surat)
                    }}
                  </h6>
                  <small class="text-muted">Pengirim</small>
                  <h6>
                    {{
                      state.detail.senderUnit === ""
                        ? "-"
                        : state.detail.senderUnit
                    }}
                  </h6>
                  <small class="text-muted">Asal Surat</small>
                  <h6>
                    {{
                      state.detail.asalsurat === ""
                        ? "-"
                        : state.detail.asalsurat
                    }}
                  </h6>
                  <!-- <h6>{{ (state.detail.document_asrat.id == "" || state.detail.document_asrat==null || state.detail.document_asrat == undefined) ? '-' : state.detail.document_asrat.id }}</h6> -->
                  <small class="text-muted">Perihal</small>
                  <h6>
                    {{
                      state.detail.subject === "" ? "-" : state.detail.subject
                    }}
                  </h6>
                </div>

                <div class="col-md-6 pull-left">
                  <small class="text-muted">Ringkasan Surat</small>
                  <h6><span v-html="state.detail.description"></span></h6>
                  <small class="text-muted">Nomor Dokumen</small>
                  <h6>
                    {{
                      state.detail.nomor_surat === ""
                        ? "-"
                        : state.detail.nomor_surat
                    }}
                  </h6>
                  <small class="text-muted">Agenda</small>
                  <h6>
                    {{
                      state.detail.agenda_number === ""
                        ? "-"
                        : state.detail.agenda_number
                    }}
                  </h6>
                </div>
              </div>
              <div class="col-md-12">
                <small class="text-muted">File</small>
                <br />
                <table class="table -table-striped">
                  <tr>
                    <td>Nama File</td>
                    <td>Pengirim</td>
                    <td>Tanggal Diunggah</td>
                    <td>Buka File</td>
                  </tr>
                  <tr v-if="!state.detail.files.length">
                    <td colspan="4">File tidak ada</td>
                  </tr>
                  <tr
                    v-for="file in state.detail.files"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <!-- <td>{{ state.detail.document_asrat.text }}</td> -->
                    <td>{{ file.nameasal.name }}</td>
                    <td>{{ formatDate(state.detail.tanggal_surat) }}</td>
                    <td>
                      <!-- <a
                        @click="downloadFileForce(file.url)"
                        class="btn btn-success text-white"
                        title="Download"
                        ><i class="fa fa-download text-white"></i> </a
                      >&nbsp; -->
                      <!-- <a
                        @click="downloadFileForce(file.url)"
                        class="btn btn-info text-white"
                        title="View"
                        ><i class="fa fa-eye text-white"></i
                      ></a> -->
                      <a
                        @click="prev(index)"
                        class="btn btn-info text-white"
                        title="View"
                      ><i class="fa fa-eye text-white"></i></a>
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>

            <gtTab title="Tracking">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <TreeView class="item" :model="treeData" />
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>

            <!-- <gtTab title="History">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h5>
                        <ol>
                          <li>
                            <span v-html="state.detail.history.document"></span>
                          </li>
                          <li>
                            <span
                              v-html="state.detail.history.konfirmasi"
                            ></span>
                          </li>
                          <li
                            v-if="state.detail.history.disposition != undefined"
                          >
                            <span
                              v-html="state.detail.history.disposition"
                            ></span>
                          </li>
                        </ol>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </gtTab> -->

            <!-- <gtTab title="Tindak Lanjut">
              <div class="col-md-12 pull-left">
                <small class="text-muted">Agenda Number</small>
                <h6>
                  {{
                    state.detail.agenda_number === ""
                      ? "-"
                      : state.detail.agenda_number
                  }}
                </h6>
                <small class="text-muted">Perihal</small>
                <h6>
                  {{ state.detail.subject === "" ? "-" : state.detail.subject }}
                </h6>
                <small class="text-muted">Nomor Dokumen</small>
                <h6>
                  {{
                    state.detail.nomor_surat === ""
                      ? "-"
                      : state.detail.nomor_surat
                  }}
                </h6>
                <small class="text-muted">Tindaklanjut Surat</small>
                <h6>
                  {{ state.detail.lanjuti === "" ? "-" : state.detail.lanjuti }}
                </h6>
              </div>
            </gtTab> -->
          </gtTabs>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div>
            <button
              type="button"
              @click="goBack()"
              class="btn btn-inverse"
              title="Kembali"
            >
              Kembali
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="card card-body printableArea">
      <h4>Petunjuk</h4>
      <table>
        <tr>
          <td>
            <span class="btn-sm btn-success">
              <i class="mdi mdi-printer"></i>Print Lembar Disposisi
            </span>
          </td>
          <td style="font-size: 13px">Untuk Print Lembar Disposisi</td>
        </tr>
      </table>
    </div>
    <!-- <h3>{{ this.state.detail }}</h3> -->
    <ModalDefault
      v-show="isVisible"
      :title="modalTitle"
      :content="modalContent"
      :action="ActionChangeable"
      :confirmation="isConfirmation"
      @close="onClose"
    />
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      myHTML: "",
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isModalVisible: false,
      isConfirmation: false,
      isInformation: false,
      isTouched: false,
      isDisabled: false,
      ActionChangeable: null,
    };
  },
  computed: {
    treeData() {
      return this.$store.state.documentIn.detail.tracking !== undefined
        ? this.$store.state.documentIn.detail.tracking
        : {};
    },
    items() {
      var data = [];
      if (this.$store.state.documentIn.items) {
        for (
          let index = 0;
          index < this.$store.state.documentIn.items.items.length;
          index++
        ) {
          let element = this.$store.state.documentIn.items.items[index];
          data[index] = {
            id: element.id,
            agenda_number: element.agenda_number,
            nomor_surat: element.nomorsurat,
            subject: element.subject,
            sifat: element.sifat,
            tanggal_diterima: element.tanggal_diterima,
            redaksi: element.redaksi,
            asal_surat: element.document_asrat,
            confirmation: element.confirmation,
          };
        }
      }
      return data;
    },

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.documentIn;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggalsurat() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalditerima() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalselesai() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggal_selesai == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggal_selesai);
        }
      }
      return text;
    },
    dataPenerima() {
      var data = [];
      const receivers = this.state.detail.receiver;
      receivers.forEach(function (value, i) {
        data[i] = { text: "- " + value.text };
      });
      return data;
    },
    dataInstruksi() {
      var data = [];
      const intruksi = this.state.detail.intruksi;
      intruksi.forEach(function (value, i) {
        data[i] = { text: "- " + value.text };
      });
      return data;
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          // bubbles: true,
          // cancelable: true,
          // view: window,
        })
      );
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "bataldisposisi") {
        this.modalTitle = "Batal Disposisi?";
        this.modalContent =
          "<p>Apakah anda yakin ingin membatalkan disposisi ini?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.rejectReceiver;
      }
    },
    addReceiver() {
      // console.log(this.$store.state.documentIn);
      const data = {
        id_dispo: this.$store.state.documentIn.detail.disposisi,
      };
      this.$store.dispatch("documentInSended/onSend", data);
      // this.$store.dispatch("documentInSended/onSend");
    },
    rejectReceiver() {
      this.$store.dispatch("documentInSended/onReject", this.$route.params);
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("documentIn/STATE", state);
      this.$store.dispatch("documentIn/getDocumentInById", this.$route.params);
    },
    onClose() {
      this.isVisible = false;
    },
    goDisposisi() {
      this.$store.dispatch("documentIn/onDisposisi", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("documentIn/onCancel");
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goConfirm() {
      this.$store.dispatch("documentIn/onConfirm");
    },
    goReturn() {
      this.$store.dispatch("documentIn/onReturn");
    },

    onDownload() {
      let leftDPenerima = [];
      let rightDPenerima = [];
      this.state.detail.dispotujuan.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          rightDPenerima.push(element);
        }
      });
      let DPenerima = [[]];

      for (var i = 0; i < leftDPenerima.length; i++) {
        DPenerima[[i]] = [
          {
            text: leftDPenerima[i] ? leftDPenerima[i].text : "",
          },
          {
            text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          },
        ];
      }
      var no = 0;
      let DMPenerima = [[]];
      let firstDPenerima = [];
      let twiceDPenerima = [];
      let thirdDPenerima = [];

      let kiriDMPenerima = [];
      let tengahDMPenerima = [];
      let kananDMPenerima = [];
      let DMInstructionMenluKiri = [];

      let kiriDataInstructionMenlu = [];

      this.state.detail.dispotujuan.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          kiriDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 1) {
          tengahDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          kananDMPenerima.push(element);
        }
      });
      const DMlength = this.state.detail.dispotujuan.length;
      this.state.detail.dispotujuan.map(function (element, index) {
        if ((index + 3) % 3 == 0) {
          firstDPenerima.push(element);
        }
        if ((index + 3) % 3 == 1) {
          twiceDPenerima.push(element);
        }
        if ((index + 3) % 3 == 2) {
          thirdDPenerima.push(element);
        }
      });

      let leftDKoordinasi = [];
      let rightDKoordinasi = [];
      this.state.detail.koordinasi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDKoordinasi.push(element.text);
        } else {
          rightDKoordinasi.push(element.text);
        }
      });
      let DKoordinasi = [[]];
      for (var i = 0; i < leftDKoordinasi.length; i++) {
        DKoordinasi[[i]] = [
          {
            text: leftDKoordinasi[i] ? leftDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDKoordinasi[i] ? rightDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      let leftInstruction = [];
      let rightInstruction = [];
      this.state.detail.instruction.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftInstruction.push(element.text);
        } else {
          rightInstruction.push(element.text);
        }
      });
      let Instruction = [[]];

      for (var i = 0; i < leftInstruction.length; i++) {
        Instruction[[i]] = [
          {
            text: leftInstruction[i] ? leftInstruction[i] : "",
          },
          {
            text: rightInstruction[i] ? rightInstruction[i] : "",
          },
        ];
      }
      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));
      // if (
      //   user.eselon == "Eselon II" ||
      //   user.eselon == "Eselon III" ||
      //   user.eselon == "Eselon IV" ||
      //   user.eselon == null
      // ) {
      documentDefinition = {
        content: [
          {
            marginLeft: 50,
            marginRight: 50,
            table: {
              headerRows: 1,
              widths: [100, "*", 100, "*"],
              body: [
                [
                  {
                    text: "Lembar Disposisi",
                    style: "tableHeader",
                    colSpan: 4,
                    margin: [20, 10, 0, 15],
                  },
                ],
              ],
            },
            layout: "headerLineOnly",
          },
          {
            style: "tableExample",
            color: "#444",
            table: {
              widths: [150, 100, 100, 130],
              headerRows: 1,
              body: [
                [
                  {
                    text: "",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "INDEKS",
                          bold: true,
                        },
                        {
                          text: this.state.detail.indeks1,
                          style: "header3",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "KODE",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.kode,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "NO. URUT",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.no_urut,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "TGL. PENYELESAIAN",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.tgl_selesai,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                ],
                [
                  {
                    colSpan: 4,
                    columns: [
                      [
                        {
                          text: "PERIHAL / ISI RINGKAS :",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.description,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {},
                  {},
                  {},
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "ASAL SURAT",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.asal_surat,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "TGL",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.tanggal_surat,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "NOMOR",
                          style: "header2",
                        },
                        {
                          text: this.state.detail.nomor_surat,
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "left",
                    columns: [
                      [
                        {
                          text: "LAMPIRAN",
                          style: "header2",
                        },
                        {
                          text: "",
                          style: "header2",
                        },
                      ],
                    ],
                  },
                ],
                [
                  {
                    colSpan: 2,
                    columns: [
                      [
                        {
                          text: "DIAJUKAN / DITERUSKAN",
                          style: "header2",
                        },
                        {
                          table: {
                            // headerRows: 0,
                            body: DPenerima,
                          },
                        },
                        {
                          text: "Isi Disposisi",
                          style: "header2",
                        },
                        {
                          table: {
                            // headerRows: 0,
                            body: Instruction,
                          },
                        },
                      ],
                    ],
                  },
                  {},
                  {
                    colSpan: 2,
                    columns: [
                      [
                        {
                          text: "INFORMASI / INSTRUKSI",
                          style: "header2",
                          rowSpan: 3,
                          colSpan: 2,
                        },
                        {
                          text: "",
                          style: "header2",
                        },
                      ],
                    ],
                  },
                  {},
                ],
              ],
            },
          },
        ],

        styles: {
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: "black",
            alignment: "center",
          },
          tableHeader1: {
            bold: true,
            fontSize: 10,
            color: "black",
            alignment: "left",
          },
          headerTitle: {
            height: 10,
            fontSize: 12,
            color: "black",
            bold: true,
          },
          headerTitleCenter: {
            height: 10,
            fontSize: 12,
            color: "black",
            bold: true,
            alignment: "center",
          },
          headerTitleRight: {
            height: 10,
            fontSize: 12,
            color: "black",
            bold: true,
            alignment: "right",
          },
          header: {
            bold: true,
            fontSize: 26,
            color: "black",
            alignment: "center",
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },
          header233: {
            fontSize: 8,
            color: "black",
          },
          TableInstruksi: {
            fontSize: 8,
            color: "black",
          },
          TableInstruksi2: {
            fontSize: 4,
            color: "black",
          },
          header1: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "center",
          },
          header2: {
            bold: true,
            fontSize: 10,
            color: "black",
            alignment: "left",
          },
          header4: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "left",
          },
          tableTitleDetail: {
            bold: true,
            fontSize: 12,
            color: "black",
            alignment: "center",
          },
        },
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4",
      };
      // }

      pdfMake.createPdf(documentDefinition).print();
    },

    approve(val) {
      let payload = {
        document: this.$route.params.id,
        approval: val,
        message: this.myHTML,
      };
      this.$store.dispatch("documentIn/approveDoc", JSON.stringify(payload));
    },
  },
};
</script>
